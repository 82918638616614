<template lang="pug">
#container
  canvas(ref="canvas")
  img.title(src="~@/assets/safety/title.png", alt)
  img.nav(src="~@/assets/safety/nav.png", alt)

</template>

<script>
import * as THREE from 'three';
import { ref, onMounted } from 'vue';
import { presetThree, createBloomPass } from '../utils';
import { createWave } from './wave';
import { createShield } from './shield';
import { createCurve } from './curve';
import { GUI } from 'three/examples/jsm/libs/lil-gui.module.min.js';

export default {
  name: 'Shield',
  setup() {
    const canvas = ref(null);

    onMounted(async () => {
      const { camera, scene, renderer, controls } = presetThree(canvas.value);

      camera.position.z = 10;

      const color = [
        new THREE.Color(0x2660f5), // 粒子0
        new THREE.Color(0x2660f5), // 粒子1
        new THREE.Color(0x4988f7), // 曲线
        new THREE.Color(0x243652), // 曲线粒子
        new THREE.Color(0x000000), // 背景色
      ];

      const group = new THREE.Group();
      const gui = new GUI();

      const { shieldParticles, update: updateShield } = await createShield(gui);
      shieldParticles.scale.set(0.07, 0.07, 0.07);
      shieldParticles.position.set(2, -0.5, 0);
      shieldParticles.rotation.set(-Math.PI / 2, 0, 0);
      group.add(shieldParticles);

      const { particles: waveObject, update: updateWave } = createWave(color);
      group.add(waveObject);

      const { curveGroup, update: updateCurve } = createCurve([color[2], color[3]]);
      curveGroup.position.set(2, -0.3, -0.7);
      group.add(curveGroup);

      group.rotateX(Math.PI / 4);
      group.rotateZ(Math.PI / 12);

      scene.add(group);

      const composer = createBloomPass(scene, camera, renderer, gui);
      const clock = new THREE.Clock();

      const anime = () => {
        const delta = clock.getDelta();
        window.requestAnimationFrame(anime);
        controls.update();
        updateCurve();
        updateWave();
        updateShield();
        renderer.render(scene, camera);
        composer.render(delta);
      };

      anime();

      const params = {
        粒子颜色0: color[0],
        粒子颜色1: color[1],
        曲线颜色: color[2],
        曲线粒子颜色: color[3],
        背景色: color[4],
      };
      gui.addColor(params, '粒子颜色0').onChange(() => {
        waveObject.material.uniforms.color0.value.set(params['粒子颜色0']);
      });
      gui.addColor(params, '粒子颜色1').onChange(() => {
        waveObject.material.uniforms.color1.value.set(params['粒子颜色1']);
      });
      gui.addColor(params, '曲线颜色').onChange(() => {
        curveGroup.children.forEach((child) => {
          if (child.name === 'curve')
            child.material.uniforms.lineColor.value.set(params['曲线颜色']);
        });
      });
      gui.addColor(params, '曲线粒子颜色').onChange(() => {
        curveGroup.children.forEach((child) => {
          if (child.name === 'sphere') child.material.color.set(params['曲线粒子颜色']);
        });
      });
      gui.addColor(params, '背景色').onChange(() => {
        renderer.setClearColor(params['背景色']);
        composer.render(0.016);
      });
    });

    return {
      canvas,
    };
  },
};
</script>

<style lang="stylus" scoped>
#container
  position fixed
  top 0
  left 0
  width 100vw
  height 100vh
  background-color black

  canvas
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    z-index 1

  .title
    position absolute
    z-index 2
    width 25vw
    height auto
    top 38vh
    left 80px

  .nav
    position absolute
    top 0
    left 0
    z-index 2
    width 100vw
    height auto
</style>
