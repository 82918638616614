import * as THREE from 'three';
import vertex from './wave.vs';
import fragment from './wave.fs';

const SEPARATION = 0.1;
const AMOUNT_X = 300;
const AMOUNT_Y = 300;

export function createWave(color) {
  const numParticles = AMOUNT_X * AMOUNT_Y;
  const positions = new Float32Array(numParticles * 3);
  const random = new Float32Array(numParticles * 2);

  let i = 0;
  let j = 0;

  const genRandom = () => {
    const lowerRangeProbability = 0.6;
    const upperRangeProbability = 0.4;
    const randomNumber = Math.random();

    if (randomNumber < lowerRangeProbability) return Math.floor(Math.random() * 500) / 1000;
    else return (Math.floor(Math.random() * 201) + 800) / 1000;
  };

  for (let ix = 0; ix < AMOUNT_X; ix++) {
    for (let iy = 0; iy < AMOUNT_Y; iy++) {
      positions[i] = ix * SEPARATION - (AMOUNT_X * SEPARATION) / 2;
      positions[i + 1] = 0;
      positions[i + 2] = iy * SEPARATION - (AMOUNT_Y * SEPARATION) / 2;
      random[j] = Math.random();
      random[j + 1] = genRandom();

      i += 3;
      j += 2;
    }
  }

  const geometry = new THREE.BufferGeometry();
  geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
  geometry.setAttribute('random', new THREE.BufferAttribute(random, 2));

  const material = new THREE.ShaderMaterial({
    uniforms: {
      color0: { value: color[0] },
      color1: { value: color[1] },
      time: { value: 0 },
    },
    vertexShader: vertex,
    fragmentShader: fragment,
    transparent: true,
    depthWrite: false,
  });

  const particles = new THREE.Points(geometry, material);

  const update = () => {
    material.uniforms.time.value += 0.01;
  };

  return { particles, update };
}
