import * as THREE from 'three';
import fragment from './curve.fs';
import vertex from './curve.vs';

export function createCurve(color) {
  const radius = [3.6, 3.6, 3.6, 3.6].map((r) => r * 1.1);
  const posY = [0.7, 0.2, -0.1, -0.6];
  const tubeRadius = [0.0035, 0.0035, 0.0035, 0.0035];
  const tubeSeq = [2, 3, 5, 1];
  const spherePoints = [700, 1400, 1000, 1800];
  const curveGroups = [];

  const sphereGroups = [];
  const sphereGeometry = new THREE.SphereGeometry(0.02, 9, 9);
  const sphereMaterial = new THREE.MeshBasicMaterial({ color: color[1] });

  const getEllipse = (factor) => {
    const r = radius[factor];
    const numPoints = 100;

    const points = [];
    for (let i = 0; i < numPoints; i++) {
      const angle = (2 * Math.PI * i) / numPoints;
      const x = r * Math.cos(angle);
      const z = r * Math.sin(angle);
      points.push(new THREE.Vector3(x, posY[factor], z));
    }

    return points;
  };

  const getCurveMaterial = (dashed, lineOpacity) => {
    return new THREE.ShaderMaterial({
      uniforms: {
        time: {
          value: 0,
        },
        dashed: {
          value: dashed,
        },
        opacity: {
          value: 1,
        },
        lineOpacity: {
          value: lineOpacity,
        },
        lineColor: {
          value: color[0], // new THREE.Color(0x5B9EF7),
        },
        lineStep: {
          value: new THREE.Vector3(-0.06, 0.5, 0.66),
        },
        speed: {
          value: 1,
        },
      },
      vertexShader: vertex,
      fragmentShader: fragment,
      transparent: true,
    });
  };

  const getCurveGeometry = (factor = 0) => {
    const curvePoints = getEllipse(factor);
    const curve = new THREE.CatmullRomCurve3(curvePoints, true, 'catmullrom', 0.5);
    const curveGeometry = new THREE.TubeGeometry(curve, 200, tubeRadius[factor], 8, false);
    return { curveGeometry, curve };
  };

  const getSphere = (curve, index, count = 3) => {
    const curvePoints = curve.getPoints(spherePoints[index]);
    const spheres = [];

    const createMotion = (obj, start = 0) => {
      let i = start;
      obj.position.copy(curvePoints[i]);

      return () => {
        const point = curvePoints[i];
        obj.position.copy(point);
        i = (i + 1) % curvePoints.length;
      };
    };

    for (let i = 0; i < count; i++) {
      const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
      sphere.name = 'sphere';
      const size = Math.random() * 0.5 + 0.7;
      sphere.scale.set(size, size, size);
      sphere._move = createMotion(sphere, Math.floor(curvePoints.length * Math.random()));
      spheres.push(sphere);
    }

    return spheres;
  };

  for (let i = 0; i < radius.length; i++) {
    const { curveGeometry, curve } = getCurveGeometry(i);
    const curveMesh = new THREE.Mesh(curveGeometry, getCurveMaterial(tubeSeq[i], 0.1));
    curveMesh.name = 'curve';
    curveGroups.push(curveMesh);

    sphereGroups.push(...getSphere(curve, i));
  }

  const update = () => {
    curveGroups.forEach((curve) => {
      curve.material.uniforms.time.value += 0;
      curve.rotation.y -= 0.01;
    });
    sphereGroups.forEach((sphere) => {
      sphere._move();
    });
  };

  const curveGroup = new THREE.Group();
  curveGroup.add(...curveGroups, ...sphereGroups);

  return { curveGroup, update };
}
