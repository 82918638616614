import * as THREE from 'three';
import vertex from './shield.vs';
import fragment from './shield.fs';
import { loadFBX, loadTex } from '../utils';

export async function createShield(gui) {
  const shield = await loadFBX('assets/models/safety/shield.fbx');
  const masks = await Promise.all(
    ['0', '2'].map((v) => loadTex(`assets/models/safety/mask-o-${v}.jpg`)),
  );

  const geometry = shield.children[0].geometry;
  const random = new Float32Array(geometry.attributes.position.array.length);

  for (let i = 0; i < random.length; i++) random[i] = Math.random();

  geometry.setAttribute('random', new THREE.BufferAttribute(random, 1));

  const pointsMaterial = new THREE.ShaderMaterial({
    uniforms: {
      color0: { value: new THREE.Color(0x2660f5) },
      color1: { value: new THREE.Color(0x00b94a) },
      time: { value: 0 },
      maskTexture: { value: masks[0] },
    },
    vertexShader: vertex,
    fragmentShader: fragment,
    transparent: true,
    depthWrite: false,
  });

  const shieldParticles = new THREE.Points(geometry, pointsMaterial);

  const pickOne = {
    A: true,
    B: false,
    // C: false,
    // D: false,
  };

  gui
    .add(pickOne, 'A')
    .name('Texture A')
    .listen()
    .onChange(function(v) {
      pickOne.B = false;
      // pickOne.C = false;
      // pickOne.D = false;

      pointsMaterial.uniforms.maskTexture.value = masks[0];
    });
  gui
    .add(pickOne, 'B')
    .name('Texture B')
    .listen()
    .onChange(function(v) {
      pickOne.A = false;
      // pickOne.C = false;
      // pickOne.D = false;

      pointsMaterial.uniforms.maskTexture.value = masks[1];
    });
  // gui
  //   .add(pickOne, 'C')
  //   .name('Texture C')
  //   .listen()
  //   .onChange(function(v) {
  //     pickOne.A = false;
  //     pickOne.B = false;
  //     pickOne.D = false;
  //     pointsMaterial.uniforms.maskTexture.value = masks[2];
  //   });
  // gui
  //   .add(pickOne, 'D')
  //   .name('Texture D')
  //   .listen()
  //   .onChange(function(v) {
  //     pickOne.A = false;
  //     pickOne.B = false;
  //     pickOne.C = false;
  //     pointsMaterial.uniforms.maskTexture.value = masks[3];
  //   });

  const update = () => {
    pointsMaterial.uniforms.time.value += 0.01;
  };

  return { shieldParticles, update };
}
